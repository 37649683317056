
import React from 'react';
import PropTypes from 'prop-types';
import {omit} from 'ramda';

/**
 * Textarea is a wrapper for the <textarea> HTML5 element.
 * For detailed attribute info see:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
const Textarea = (props) => {
    const dataAttributes = {};
    if(props.loading_state && props.loading_state.is_loading) {
        dataAttributes['data-dash-is-loading'] = true;
    }

    return (
        <textarea
            onClick={() => props.setProps({
                n_clicks: props.n_clicks + 1,
                n_clicks_timestamp: Date.now()
            })}
            {...omit(['n_clicks', 'n_clicks_timestamp', 'loading_state', 'setProps'], props)}
            {...dataAttributes}
        >
            {props.children}
        </textarea>
    );
};

Textarea.defaultProps = {
    n_clicks: 0,
    n_clicks_timestamp: -1,
};

Textarea.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    'id': PropTypes.string,

    /**
     * The children of this component
     */
    'children': PropTypes.node,

    /**
     * An integer that represents the number of times
     * that this element has been clicked on.
     */
    'n_clicks': PropTypes.number,

    /**
     * An integer that represents the time (in ms since 1970)
     * at which n_clicks changed. This can be used to tell
     * which button was changed most recently.
     */
    'n_clicks_timestamp': PropTypes.number,

    /**
     * A unique identifier for the component, used to improve
     * performance by React.js while rendering components
     * See https://reactjs.org/docs/lists-and-keys.html for more info
     */
    'key': PropTypes.string,

    /**
     * The ARIA role attribute
     */
    'role': PropTypes.string,

    /**
     * A wildcard data attribute
     */
    'data-*': PropTypes.string,

    /**
     * A wildcard aria attribute
     */
    'aria-*': PropTypes.string,

    /**
     * Indicates whether controls in this form can by default have their values automatically completed by the browser.
     */
    'autoComplete': PropTypes.string,

    /**
     * The element should be automatically focused after the page loaded.
     */
    'autoFocus': PropTypes.oneOfType([
        PropTypes.oneOf(['autoFocus', 'autofocus', 'AUTOFOCUS']),
        PropTypes.bool
     ]),

    /**
     * Defines the number of columns in a textarea.
     */
    'cols': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Indicates whether the user can interact with the element.
     */
    'disabled': PropTypes.oneOfType([
        PropTypes.oneOf(['disabled', 'DISABLED']),
        PropTypes.bool
     ]),

    /**
     * Indicates the form that is the owner of the element.
     */
    'form': PropTypes.string,

    /**
     * Provides a hint as to the type of data that might be entered by the user while editing the element or its contents. The attribute can be used with form controls (such as the value of textarea elements), or in elements in an editing host (e.g., using contenteditable attribute).
     */
    'inputMode': PropTypes.string,

    /**
     * Defines the maximum number of characters allowed in the element.
     */
    'maxLength': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Defines the minimum number of characters allowed in the element.
     */
    'minLength': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Name of the element. For example used by the server to identify the fields in form submits.
     */
    'name': PropTypes.string,

    /**
     * Provides a hint to the user of what can be entered in the field.
     */
    'placeholder': PropTypes.string,

    /**
     * Indicates whether the element can be edited.
     */
    'readOnly': PropTypes.string,

    /**
     * Indicates whether this element is required to fill out or not.
     */
    'required': PropTypes.oneOfType([
        PropTypes.oneOf(['required', 'REQUIRED']),
        PropTypes.bool
     ]),

    /**
     * Defines the number of rows in a text area.
     */
    'rows': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Indicates whether the text should be wrapped.
     */
    'wrap': PropTypes.string,

    /**
     * Defines a keyboard shortcut to activate or add focus to the element.
     */
    'accessKey': PropTypes.string,

    /**
     * Often used with CSS to style elements with common properties.
     */
    'className': PropTypes.string,

    /**
     * Indicates whether the element's content is editable.
     */
    'contentEditable': PropTypes.string,

    /**
     * Defines the ID of a <menu> element which will serve as the element's context menu.
     */
    'contextMenu': PropTypes.string,

    /**
     * Defines the text direction. Allowed values are ltr (Left-To-Right) or rtl (Right-To-Left)
     */
    'dir': PropTypes.string,

    /**
     * Defines whether the element can be dragged.
     */
    'draggable': PropTypes.string,

    /**
     * Prevents rendering of given element, while keeping child elements, e.g. script elements, active.
     */
    'hidden': PropTypes.oneOfType([
        PropTypes.oneOf(['hidden', 'HIDDEN']),
        PropTypes.bool
     ]),

    /**
     * Defines the language used in the element.
     */
    'lang': PropTypes.string,

    /**
     * Indicates whether spell checking is allowed for the element.
     */
    'spellCheck': PropTypes.string,

    /**
     * Defines CSS styles which will override styles previously set.
     */
    'style': PropTypes.object,

    /**
     * Overrides the browser's default tab order and follows the one specified instead.
     */
    'tabIndex': PropTypes.string,

    /**
     * Text to be displayed in a tooltip when hovering over the element.
     */
    'title': PropTypes.string,

    /**
     * Object that holds the loading state object coming from dash-renderer
     */
    'loading_state': PropTypes.shape({
        /**
         * Determines if the component is loading or not
         */
        is_loading: PropTypes.bool,
        /**
         * Holds which property is loading
         */
        prop_name: PropTypes.string,
        /**
         * Holds the name of the component that is loading
         */
        component_name: PropTypes.string,
    }),

    'setProps': PropTypes.func
};

export default Textarea;
